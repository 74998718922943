import Image from "next/image";
import Link from "next/link";
import React, { FC, ReactNode } from "react";

import styles from "./Header.module.css";

type HeaderProps = {
  children?: ReactNode;
};

const Header: FC<HeaderProps> = () => {
  return (
    <div className={styles.root}>
      <Link href="https://www.konfir.com/" className={styles.logoContainer}>
        <Image width="97" height="32" src="/about-us/konfir-logo.svg" alt="Konfir logo" />
      </Link>
    </div>
  );
};

export default Header;
