import { CircularProgress } from "@material-ui/core";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import NoAuthLayout from "@/components/layouts/NoAuthLayoutNew/NoAuthLayout";

import Home from "../components/Home";
import useCurrentUser from "../hooks/useCurrentUser";

const HomePage = () => {
  const router = useRouter();
  const user = useCurrentUser();

  useEffect(() => {
    if (router.isReady) {
      if (user.isLoggedIn) {
        router.push("/requests");
      } else if (!user.isLoading && !user.isLoggedIn) {
        router.push("/sign-in");
      }
    }
  }, [router.isReady, user.isLoggedIn, user.isLoading]);

  const redirectToSignIn = () => {
    router.push("/sign-in");
  };

  // return <Home redirectToSignIn={redirectToSignIn} />;

  return (
    <NoAuthLayout>
      <CircularProgress />
    </NoAuthLayout>
  );
};

export default HomePage;
