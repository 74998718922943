import Link from "next/link";
import React, { FC, ReactNode } from "react";

import styles from "./Links.module.css";

type LinksProps = {
  children?: ReactNode;
};

const Links: FC<LinksProps> = () => {
  return (
    <ul className={styles.root}>
      <li>
        <Link href="/legals/privacy-notice" target="_blank">
          Privacy Notice
        </Link>
      </li>
      <li>
        <Link href="/legals/cookie-notice" target="_blank">
          Cookie Policy
        </Link>
      </li>
      <li>
        <Link href="/legals/organisation-terms-of-use" target="_blank">
          Terms of Use
        </Link>
      </li>
    </ul>
  );
};

export default Links;
