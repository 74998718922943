import { getYear } from "date-fns/fp";
import React, { FC, ReactNode } from "react";

import Links from "@/components/layouts/NoAuthLayoutNew/Footer/Links/Links";

import styles from "./Footer.module.css";

type FooterProps = {
  children?: ReactNode;
};

const Footer: FC<FooterProps> = () => {
  return (
    <div className={styles.root}>
      <div data-testid="copyright">© Konfir (UK) Ltd. {getYear(new Date())} All rights reserved.</div>
      <Links />
    </div>
  );
};

export default Footer;
