import classnames from "classnames";
import React, { Children, FC, ReactNode } from "react";

import Footer from "@/components/layouts/NoAuthLayoutNew/Footer/Footer";
import Header from "@/components/layouts/NoAuthLayoutNew/Header/Header";

import styles from "./NoAuthLayout.module.css";

export type NoAuthLayoutNewProps = {
  children: ReactNode;
};

const NoAuthLayout: FC<NoAuthLayoutNewProps> = ({ children }) => {
  const isSingleChild = Children.count(children) === 1;
  const contentClasses = classnames(styles.content, {
    [styles.singleChild]: isSingleChild,
  });

  return (
    <div className={styles.root}>
      <Header />
      <div className={contentClasses}>{children}</div>
      <Footer />
    </div>
  );
};

export default NoAuthLayout;
